<template>
  <div class="container">
    <alert v-if="providers.isLoading" class="light-shadow" />
    <div
      class="header mb-3 mp-print-0"
      v-if="providerData && !providers.isLoading"
    >
      <div class="header-body mp-print-0">
        <div class="row">
          <div class="col noPrint">
            <h6 class="header-pretitle">Provider</h6>
            <div class="d-flex align-items-center flex-wrap">
              <h1 class="header-title">
                {{ providerData.last }}, {{ providerData.first
                }}<span class="ml-3 h3">- ID {{ providerData.id }}</span>
              </h1>
              <button
                class="btn btn-sm btn-outline-blue ml-sm-4 py-0 px-0 px-sm-2"
                @click="showNoteModal"
                :disabled="notes.isSaving"
                v-if="user.isAdmin || user.isManagingAdmin"
              >
                {{ providerData.admin_note ? "Edit Note" : "Add Note" }}
              </button>
            </div>
            <p
              class="small mt-2 px-3 py-2 rounded note-paragraph"
              v-if="
                providerData.admin_note &&
                (user.isAdmin || user.isManagingAdmin)
              "
            >
              {{ providerData.admin_note }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <provider-navbar :itemsIdToDisplay="itemsToDisplay" />
          </div>
        </div>
      </div>
    </div>
    <router-view v-if="!providers.isLoading && providerData" />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { default as ProviderNavbar } from "./Navbar";
import Swal from "sweetalert2";

export default {
  name: "ProviderProfile",
  data() {
    return {
      note: "",
    };
  },

  components: {
    ProviderNavbar,
  },
  computed: {
    ...mapState(["providers"]),
    ...mapState({
      user: (state) => state.auth.user,
      itemsToDisplay: function () {
        // Tabs to view for user in the header.
        if (this.user && this.user.isPatient) {
          return ["profile"];
        } else if (this.user && this.user.isProvider) {
          return [
            "profile",
            "profession",
            "payroll",
            "documents",
            "timeline",
            "contacts",
            "events",
            "integration",
            "shared-recordings",
            "availability",
          ];
        }
        return undefined;
      },
      notes: (state) => state.providers.notes,
    }),
    id: function () {
      return this.$route.params.id;
    },
    providerData: function () {
      return this.providers.provider;
    },
  },
  mounted() {
    this.$store.dispatch("providers/get", { id: this.id });
  },
  methods: {
    ...mapActions({
      saveProviderNote: "providers/notes/saveProviderNote",
    }),
    showNoteModal() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black",
          cancelButton: "btn btn-danger mr-4",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `${this.providerData.last}, ${this.providerData.first} Note`,
          input: "textarea",
          inputPlaceholder: `Type your note about ${this.providerData.last}, ${this.providerData.first} here...`,
          inputValue: this.providerData.admin_note,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              provider_id: this.providerData.id,
              admin_note: result.value,
            };
            this.saveProviderNote(payload)
              .then((res) => {
                if (res && res.data) {
                  this.providerData.admin_note = res.data.admin_note;
                  Swal.fire("Success", res.message, "success");
                }
              })
              .catch((err) => {
                Swal.fire(
                  "Error",
                  err?.data?.error?.message ?? "Something went wrong...",
                  "error"
                );
              });
          }
        });
    },
  },
};
</script>

<style>
.note-paragraph {
  display: inline-block;
  border: 1px solid #779fd0;
  background-color: #ffffff96;
}
</style>
